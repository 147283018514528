import { template as template_102477d48c664945a9443799a5ce3b34 } from "@ember/template-compiler";
const SidebarSectionMessage = template_102477d48c664945a9443799a5ce3b34(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
