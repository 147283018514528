import { template as template_546b4d15dd5147d6accac5e9d1c0ea4a } from "@ember/template-compiler";
const WelcomeHeader = template_546b4d15dd5147d6accac5e9d1c0ea4a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
